@use "@/styles/constants/devices.scss" as *;

.leftTramline {
  position: sticky;
  z-index: 1;
  top: 160px;
  height: 600px;
  text-align: right;
}

.rightTramline {
  position: sticky;
  z-index: 1;
  top: 160px;
  height: 600px;
}

.leftTramline,
.rightTramline {
  overflow: hidden;

  @media screen and (min-width: $tablet) {
    display: none;
  }

  @media screen and (min-width: $laptop) {
    display: block;
  }
}
