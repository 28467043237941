@use "@/styles/constants/devices.scss" as *;

.modalOverlay {
  position: fixed;
  z-index: 999;
  background: var(--modal-overlayBackgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;

  // inset: 0 does not work on versions lower than iOS 14 so we use top, bottom etc
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalContainer {
  text-align: center;
  background-color: var(--modal-containerBackgroundColor);
  position: fixed;
  box-shadow: var(--modal-overlayBackgroundColor) 0 0 5px 0;
  border-radius: 8px;
  width: 100%;
  padding-bottom: 16px;
  overflow-y: scroll;

  @media screen and (max-width: $laptop) {
    width: 90%;
    max-height: 80vh;
  }
}

.modalHeader {
  display: flex;
  background-color: var(--modal-headerBackgroundColor);
  justify-content: center;
  padding: 24px 16px;
  border-radius: 8px 8px 0 0;
  align-items: center;
  margin-bottom: 8px;

  @media screen and (max-width: $laptop) {
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  h2 {
    font-size: 1.2rem;
    font-family: var(--font-montserrat);
    text-transform: uppercase;
    font-weight: 700;
    color: var(--modal-primaryTextColor);
    margin: 0;
    // 50px = 16px + 34px (modalHeader right padding + button width)
    width: calc(100% - 50px);
  }

  button {
    position: absolute;
    right: 10px;
    top: 22px;
    color: var(--modal-primaryTextColor);
    font-weight: bolder;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  svg {
    width: 22px;
    height: 22px;

    path {
      fill: var(--modal-primaryTextColor);
    }
  }
}

.childrenContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  // If there are three interstitials, apply these rules
  div:first-child:nth-last-child(3),
  div:first-child:nth-last-child(3) ~ div {
    &[data-featured="false"]:nth-child(1) {
      order: -1;
    }

    // Display the featured offer in the centre
    &[data-featured="true"] {
      order: 0;
    }

    &[data-featured="false"]:nth-child(2) {
      order: 1;
    }

    // Display the featured offer first on mobile
    @media screen and (max-width: $tablet) {
      &[data-featured="true"] {
        order: -999;
      }
    }
  }

  // If there are two interstitials, apply these rules on desktop
  div:first-child:nth-last-child(2),
  div:first-child:nth-last-child(2) ~ div {
    &[data-featured="true"] {
      order: 0;
    }

    &[data-featured="false"] {
      order: 1;
    }
  }
}

.freeBetsLink {
  display: block;
  width: 36%;
  margin: 20px auto;
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  font-size: 0.8rem;
  font-family: var(--font-montserrat);
  font-weight: 600;
  background: var(--modal-allOffersButtonBackgroundColor);
  color: var(--modal-primaryTextColor);
  border-radius: 4px;

  &:hover {
    box-shadow: inset 0 0 0 10em var(--modal-allOffersButtonOnHoverColor);
  }

  @media screen and (max-width: $tablet) {
    margin: 16px 23px;
    width: unset;
  }
}

.gambleSafeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  > div {
    > div {
      border-color: var(--interstitial-contentFontColor);
    }
  }
}

.minimumAgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 2px solid black;
  border-radius: 50%;
  color: black;
  text-align: center;
  font-size: 1rem;
  padding: 0 8px;
  box-sizing: border-box;
}

.problemGamblingLink {
  margin-left: 25px;
}
