.mpu {
  margin-bottom: 10px;

  img {
    position: unset !important;
  }

  a {
    display: flex;
    justify-content: center;
  }
}
