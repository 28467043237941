@use "@/styles/constants/devices.scss" as *;

.interstitialContainer {
  height: auto;
  border: 1px solid var(--interstitial-containerBackgroundColor);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  margin: 48px 8px 0;

  &.featuredOfferContainer {
    border: 1px solid var(--interstitial-featuredContainerBackgroundColor);
    margin: 16px 8px 0;

    span {
      background-color: var(--interstitial-featuredContainerBackgroundColor);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      font-family: var(--font-montserrat);
      font-weight: 600;
      font-size: 0.8rem;
      color: var(--interstitial-featuredContainerFontColor);

      padding: 8px;
      text-align: center;
    }
  }

  @media screen and (max-width: $tablet) {
    margin: 16px 16px 0 !important;
  }
}

.bookmakerLogoAndTitleContainer {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 0;
  width: 100%;
  border-radius: 8px 8px 0 0;
  margin-bottom: 12px;

  @media screen and (max-width: $tablet) {
    display: flex;
    align-items: flex-start;
    text-align: left;
    width: unset;
    padding: 16px;
    margin: 0;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid var(--interstitial-containerBackgroundColor);

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.interstitialContent {
  padding: 0 16px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  flex-grow: 1;
  height: 100%;

  h2 {
    color: var(--interstitial-contentFontColor);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25em;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @media screen and (max-width: $tablet) {
      font-size: 0.8rem;
      display: block;
      margin: 0 0 0 10px;
      min-height: unset;
    }
  }

  p {
    color: var(--interstitial-contentFontColor);
    font-size: 0.5rem;
    letter-spacing: 0.05em;
    line-height: 1.25em;
    text-align: center;
    margin-bottom: 12px;

    @media screen and (max-width: $tablet) {
      padding: 16px 16px 0;
      margin: 0;
      text-align: left;
    }
  }

  a {
    text-decoration: none;
    font-size: 0.8125rem;
    line-height: 1;
    margin-top: auto;
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-family: var(--font-montserrat);
    font-weight: 600;
    border-radius: 4px;
  }

  a:hover {
    box-shadow: inset 0 0 0 10em var(--interstitial-buttonOnHoverColor);
  }

  @media screen and (max-width: $tablet) {
    padding: 0;
    margin-bottom: 0;
  }
}

.ctaButtonContainer {
  margin-top: auto;

  @media screen and (max-width: $tablet) {
    padding: 16px;
  }
}
