@use "@/styles/constants/devices.scss" as *;
@import "@/components/sharedStyles/SharedContent.module.scss";

.offerSidebarContainer {
  background-color: var(--contentBackground);
  margin-bottom: 10px;

  @media only screen and (min-width: $tablet) {
    border-radius: 8px;
  }
}

.offerSidebarBodyContainer {
  margin: 0 16px;
  padding-bottom: 16px;
  &--topPadding {
    @extend .offerSidebarBodyContainer;
    padding-top: 16px;
  }
}

.offerSidebarHeader {
  @extend .cardListHeader;
  margin: 0;
  padding: 16px;
  background-color: var(--contentHeaderBackground);
  color: var(--contentHeaderFontColor);

  @media only screen and (min-width: $tablet) {
    border-radius: 8px 8px 0 0;
  }
}

.offerCategoryUl {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background-color: var(--contentBackground);
}

.offerCategoryLink {
  list-style: none;
  flex: 2;
  text-align: center;
  border-bottom: 2px solid var(--inactiveElement);

  &--active {
    @extend .offerCategoryLink;
    border-bottom: 2px solid var(--activeElement);
  }
}

.offerCategoryButton {
  border: none;
  color: var(--defaultText);
  background-color: var(--contentBackground);
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 0.75rem;
  padding: 16px 8px;
}
