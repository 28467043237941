@use "@/styles/constants/devices.scss" as *;

.containerGrid {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) minmax(0px, 1020px) minmax(0px, 1fr);
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr;

  /***********
   added temporarily to fix mobile layout issues with widgets
  expanding out side of content container
  *************/
  main {
    max-width: 100vw;
  }

  @media only screen and (min-width: $tablet) {
    grid-template-columns: minmax(0px, 780px) 358px;
    gap: 20px;
    margin: 0 16px;
  }
}

.widgetsAtTopOfPageContainer {
  margin: 0;

  @media only screen and (min-width: $mobile) {
    margin: 16px;
  }
}

.sidebarDisabled {
  @media only screen and (min-width: $tablet) {
    grid-template-columns: minmax(0, 1138px) 0;
    gap: 0;
    margin: 0 16px;
  }
}

.pageTitle {
  font-family: var(--font-montserrat);
  font-size: 1rem;
  font-weight: 800;
  color: var(--defaultText);
  text-transform: uppercase;
  max-width: 1020px;
  margin: 0 auto 16px;

  @media only screen and (max-width: $tablet) {
    margin-left: 16px;
  }
}

.pageSubtitle {
  font-size: 0.8125rem;
  font-weight: 800;
  background: var(--contentHeaderBackground);
  color: var(--contentHeaderFontColor);
  padding: 16px;
  margin: 0;

  @media only screen and (min-width: $tablet) {
    border-radius: 8px 8px 0 0;
  }
}

.pageFpSubtitle {
  @extend .pageTitle;
}
