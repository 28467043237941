@use "@/styles/constants/devices.scss" as *;

.breadcrumbsContainer {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding-left: 16px;

  @media only screen and (min-width: $mobile) {
    padding-left: 0;
  }
}

.breadcrumbsLink {
  font-size: 0.75rem;
  font-weight: 500;
  text-decoration: none;
  color: var(--defaultText);
}

.breadcrumbIcon {
  height: 0.6rem;
  margin: 0 0.5rem;
}
