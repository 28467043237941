.socialsContent {
  padding: 8px 16px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;

  a:first-of-type {
    margin-top: 0;
  }

  a:last-of-type {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: var(--stayConnected-fontColor);
    border-radius: 5px;
    margin: 16px auto;
  }

  svg {
    height: 20px !important;
    width: 20px !important;
    margin-right: 10px;
  }
}

.x {
  background-color: var(--stayConnected-x);
}

.facebook {
  background-color: var(--stayConnected-facebook);
}

.instagram {
  background-color: var(--stayConnected-instagram);
}

.youtube {
  background-color: var(--stayConnected-youtube);
}
