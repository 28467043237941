.viewAllLink {
  display: flex;
  align-items: center;

  a {
    font-weight: 600;
    font-size: 0.725rem;
    color: var(--contentHeaderFontColor);
    text-decoration: none;
    display: flex;
    align-items: center;
  }
}

.arrowRight {
  width: 10px;
  height: 10px;
  fill: var(--defaultText);
  margin-left: 6px;
  background-color: var(--cardWrapper-viewAllIconBackgroundColor);
  border-radius: 5px;
  padding: 7px;
}
